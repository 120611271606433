import request from '@/api/request.js'

/**
 * @Description: 单位类型
 */

// 分页查询
export function pageWmsUnitType(data) {
    return request({
        url: '/api/wmsUnitType/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsUnitType(data) {
    return request({
        url: '/api/wmsUnitType/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsUnitType(data) {
    return request({
        url: '/api/wmsUnitType/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsUnitType(data) {
    return request({
        url: '/api/wmsUnitType/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsUnitType(data) {
    return request({
        url: '/api/wmsUnitType/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsUnitType(data) {
    return request({
        url: '/api/wmsUnitType/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsUnitType(data) {
    return request({
        url: '/api/wmsUnitType/batchDelete',
        method: 'POST',
        data
    })
}

